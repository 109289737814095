@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-home {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding:8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium{
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12 px 26px;
  font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.experience,
.projects,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.experience {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.main-container {
  background: url(/static/media/main-home.33136e1f.jpg) center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.main-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.main-container > h2 {

  margin-bottom: 100px;
  margin-left: 80px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.main-btns {
  margin-top: 32px;
}

.main-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .main-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .main-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .main-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width:400px) {
  .main-container  {
    margin-top: -50px;
    background: url(/static/media/phone-main-home.36cf55da.jpg) center center/fill no-repeat;
    background-position-x: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .main-container > h2 {
    font-size: 25px;
    margin-top: 110%;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.dropdown__section {
  display: flex;
  flex-direction: column;
}

.dropdown {
  background-color: #a6a4a4;
  color: #3b5bdb;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.dropdown:hover,
.active {
  background-color: #ccc;
}

.dropdown__title {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.dropdown__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: rotate(90deg);
}

.rotate {
  transform: rotate(-90deg);
}

.dropdown__content {
  background-color: #3a3a3a;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.dropdown__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.exp-cards {
  padding: 4rem;
  background: #242424;
}

.exp-header{
  text-align: center;
  color: #87b8f5;
}

.exp-cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.exp-cards-container-internships {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.exp-cards-wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.exp-cards-wrapper-internships {
  position: relative;
  margin: 50px 0 45px;
}

.exp-cards-item-pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.exp-cards-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.exp-cards-item-img:hover{
  transform: scale(0.95);
}

.fa-java {
  color: #fff;
  margin-bottom: 20px;
  font-size: 64px;
}

.fa-python {
  color: #fff;
  margin-bottom: 20px;
  font-size: 64px;
}

.exp-icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.exp-cards-items {
  margin-bottom: 24px;
}

.exp-cards-items-internships {
  margin-bottom: 24px;
}

.exp-cards-item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.exp-cards-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.exp-cards-item-info {
  padding: 20px 30px 30px;
}

.exp-cards-item-title {
  color: #87b8f5;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.exp-cards-item-description {
  color: #a6a4a4;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.exp-icon-links{
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .exp-content-blog-container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .exp-cards-items-internships {
    display: flex;
  }
}

@media only screen and (min-width: 1024px) {
  .exp-cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .exp-cards-item {
    margin-bottom: 2rem;
  }
}

.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
  color: #3b5bdb;
}

.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards-wrapper {
  position: relative;
  margin: 50px 0 45px;
}


.fa-java {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 100px;
}

#project-icon.fa-python {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 100px;
}

#project-icon.fa-slack {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 100px;
}

#project-icon.fa-react {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 100px;
}

#project-icon.fa-android {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 100px;
}

.fa-github {
  color: #1b1919;
  margin-bottom: 20px;
  font-size: 32px;
}

.fa-github:hover{
  transform: scale(1.1);
}

.fa-youtube {
  color: #eb3423;
  margin-bottom: 20px;
  font-size: 32px;
  margin-left: 5px;
}

.fa-youtube:hover{
  transform: scale(1.1);
}

.icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.cards-items {
  margin-bottom: 24px;
}

.cards-item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards-item-info {
  padding: 20px 30px 30px;
}

.cards-item-title {
  color: #3b5bdb;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.cards-item-description {
  color: #8c8c8c;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.icon-links{
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .content-blog-container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards-item {
    margin-bottom: 2rem;
  }
}
.mtn-cards {
  padding: 4rem;
  background: #242424;
}

.mtn-header{
  text-align: center;
  color: #87b8f5;
}

.mtn-desc{
  text-align: center;
  color: #87b8f5;
}

.mtn-cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.mtn-cards-wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.mtn-cards-item-pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.mtn-cards-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.mtn-cards-item-img:hover{
  transform: scale(0.95);
}


.mtn-icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.mtn-cards-items {
  margin-bottom: 24px;
}

.mtn-cards-item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.mtn-cards-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.mtn-cards-item-info {
  padding: 20px 30px 30px;
}

.mtn-cards-item-title {
  color: #87b8f5;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.mtn-cards-item-description {
  color: #8c8c8c;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.mtn-icon-links{
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .mtn-content-blog-container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .mtn-cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .mtn-cards-item {
    margin-bottom: 2rem;
  }
}

.footer-container {
  background-color: #fff;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fa-compass {
  color: #242424;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 55px;
} 

.fa-compass:hover{
  transform: scale(1.1);
}

.fa-github-square {
  color: #242424;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 55px;
} 

.fa-github-square:hover{
  transform: scale(1.1);
}

.fa-linkedin {
  color: #242424;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 55px;
}

.fa-linkedin:hover{
  transform: scale(1.1);
}

.fa-envelope-square {
  color: #242424;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 55px;
}

.fa-envelope-square:hover{
  transform: scale(1.1);
}

.fa-file-alt {
  color: #242424;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 47px;
}

.fa-file-alt:hover{
  transform: scale(1.1);
}

.footer-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #242424;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #242424;
}

.footer-link-items a {
  color: #242424;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #242424;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #242424;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #242424;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

