
.main-container {
  background: url('../images/main-home.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.main-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.main-container > h2 {

  margin-bottom: 100px;
  margin-left: 80px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.main-btns {
  margin-top: 32px;
}

.main-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .main-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .main-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .main-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width:400px) {
  .main-container  {
    margin-top: -50px;
    background: url('../images/phone-main-home.jpg') center center/fill no-repeat;
    background-position-x: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .main-container > h2 {
    font-size: 25px;
    margin-top: 110%;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
