@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

.dropdown__section {
  display: flex;
  flex-direction: column;
}

.dropdown {
  background-color: #a6a4a4;
  color: #3b5bdb;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.dropdown:hover,
.active {
  background-color: #ccc;
}

.dropdown__title {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.dropdown__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: rotate(90deg);
}

.rotate {
  transform: rotate(-90deg);
}

.dropdown__content {
  background-color: #3a3a3a;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.dropdown__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
